import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Heading } from "../components/Hero"
import { Section, Columns } from "../components/Containers"
import { Image, Text } from "../components/Core"
import { CallToAction } from "../components/CTA"
import TheaterVideo from "@components/Theater/TheaterVideo"
import SocialReviews from "../components/SocialReviews/SocialReviews"

const SingleDoctor = ({ pageContext, location, data }) => {
  const post = data.allDoctorsJson.nodes[0]
  const { language, metaDescription, metaTitle } = pageContext

  const gmbReviews = data.review.nodes[0]?.data

  return (
    <Layout
      language={language}
      navSpacer={!post.hasProfileImage}
      className="doctor-page">
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        lang={language}
      />

      {!post.hasProfileImage ? (
        <Section colorBack>
          <Columns sideColumnsSize={3}>
            <div className="column">
              <Text
                className="has-text-centered px-40--mobile"
                as="h1"
                text={post.heading}
              />
              <Text
                className="has-text-centered px-40--mobile"
                as="p"
                text={post.blurb}
              />

              <div className="doctor-page__badges centered">
                {post.badges.hasBadges &&
                  post.badges.logos.map((logo, i) => (
                    <Image
                      useAR={false}
                      className={`badge-logo-${i + 1}`}
                      key={logo}
                      publicId={logo}
                    />
                  ))}
                <SocialReviews google={gmbReviews} language={language} />
              </div>
            </div>
          </Columns>
        </Section>
      ) : (
        <Section
          zeroBottomDesktop
          zeroBottomMobile
          smallSpacing
          className="doctor-page__hero-container">
          <Columns sideColumnsSize={1}>
            <div className="column">
              <div className="doctor-page__hero">
                <div className="doctor-page__hero-content">
                  <Text className="uppercase" as="h1" text={post.heading} />
                  <Text
                    className="large px-40--mobile mb-0"
                    as="p"
                    text={post.blurb}
                  />
                  {post.aboutSection.youtube && (
                    <TheaterVideo
                      videoUrl={`https://www.youtube.com/watch?v=${post.aboutSection.youtube}`}
                      controls
                      playing
                      onlyButton
                      language={language}
                      buttonClass="contained doctor-page__theater mt-2 mb-2"
                    />
                  )}
                  {/* 
                  <div className="doctor-page__badges">
                    {post.badges.hasBadges &&
                      post.badges.logos.map((logo, i) => (
                        <Image
                          useAR={false}
                          className={`badge-logo-${i + 1}`}
                          key={logo}
                          publicId={logo}
                        />
                      ))}
                    <SocialReviews google={gmbReviews} language={language} />
                  </div> */}
                </div>
                <Image publicId={post.cutoutImage} />
              </div>
            </div>
          </Columns>
        </Section>
      )}

      {/* {post.badges.hasBadges && (
        <div className="doctor-page__badges bubble">
          {post.badges.logos.map((logo, i) => (
            <Image
              useAR={false}
              className={`badge-logo-${i + 1}`}
              key={logo}
              publicId={logo}
            />
          ))}
        </div>
      )} */}

      {!post.hasBodyImages && (
        <Section zeroTop zeroBottom className="doctor-page__about-section">
          <Columns
            className="is-desktop doctor-page__pd-top-bottom"
            sideColumnsSize={1}>
            <div className="column doctor-page__about">
              <Text
                as="h5"
                useStringReplace
                text={post.aboutSection.heading}
                className="mb-1"
              />
              <Text useStringReplace text={post.aboutSection.body} />
            </div>
            <div className="column is-2"></div>
            <div className="column">
              <div className="doctor-facts">
                {post.facts.map(({ fact }, i) => (
                  <Text
                    className={`${i > 0 ? "mt-2" : ""}`}
                    useStringReplace
                    key={i}
                    text={fact.text}
                  />
                ))}
              </div>
            </div>
          </Columns>
        </Section>
      )}

      {post.hasBodyImages && (
        <>
          <Section>
            <Columns className="is-desktop" sideColumnsSize={2}>
              <div className="column doctor-page__about">
                <Text text={post.aboutSection.body} />

                {post.aboutSection.youtube && (
                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=${post.aboutSection.youtube}`}
                    controls
                    playing
                    onlyButton
                    language={language}
                    buttonClass="contained doctor-page__theater mt-2 mx-auto"
                  />
                )}
              </div>
            </Columns>
          </Section>

          <Section>
            <Columns sideColumnsSize={2}>
              <div className="column">
                <div className="doctor-facts has-images">
                  {post.facts.map(({ fact }, i) => (
                    <div
                      className={`doctor-facts__item ${
                        fact.imageLeft ? "" : "reverse"
                      }`}>
                      <div className={fact.image ? "half" : ""}>
                        <Text key={i} text={fact.text} />
                      </div>
                      <div className={fact.image ? "half" : ""}>
                        <Image publicId={fact.image} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Columns>
          </Section>
        </>
      )}

      {/* <CallToAction
        className="doctor-page__cta"
        colorBack
        heading={post.callToAction.heading}
        blurb={post.callToAction.blurb}
        buttons={post.callToAction.buttons}
      /> */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query doctorPages($title: String!, $gmbId: String, $locations: [String]) {
    backButton: allUniquePagesJson(filter: { template: { eq: "about-us" } }) {
      nodes {
        title
      }
    }
    allDoctorsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        gmbId
        hasProfileImage
        hasBodyImages
        badges {
          hasBadges
          logos
        }
        blurb
        cutoutImage
        aboutSection {
          heading
          body
          youtube
        }
        facts {
          fact {
            text
            imageLeft
            image
          }
        }
        callToAction {
          heading
          blurb
          buttons {
            button {
              appearance
              destination
              buttonText
              href
            }
          }
        }
      }
    }
    review: allSocialJson(filter: { data: { platformId: { eq: $gmbId } } }) {
      nodes {
        data {
          platformId
          rating
          totalReviews
          url
        }
      }
    }
    allSocials: allSocialJson {
      nodes {
        data {
          rating
          totalReviews
          url
          platformId
        }
      }
    }
    matchingLocations: allLocationsJson(
      filter: { pageData: { title: { in: $locations } } }
    ) {
      nodes {
        locationData {
          gmbId
        }
      }
    }
  }
`

SingleDoctor.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
  data: PropTypes.shape({
    allDoctorsJson: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          heading: PropTypes.string,
          metaTitle: PropTypes.string,
          metaDescription: PropTypes.string,
          hasImages: PropTypes.bool
        })
      ).isRequired
    }).isRequired
  }).isRequired
}

export default SingleDoctor
